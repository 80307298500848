import React from "react";
import Reservation from "./Reservation";
import ServiceDetail from "./ServiceDetail";
import About from "./About";
import Gallery from "./Gallery";
import Home from "./Home";
import NotFound from "./NotFound";
import Service from "./Service";
import Story from "./Story";
import Venue from "../components/Venue";

export const routes = [
  {
    path: "/",
    name: "Home",
    menu: true,
    component: <Home />,
  },
  {
    path: "/sobre",
    name: "Quem somos",
    menu: true,
    component: <About />,
    exact: true,
  },
  {
    path: "/servicos",
    name: "Serviços",
    menu: true,
    component: <Service />,
    exact: true,
  },
  {
    path: "/servicos/:serviceName",
    name: "Detalhes do serviço",
    menu: false,
    component: <ServiceDetail />,
    exact: true,
  },
  {
    path: "/galeria-de-fotos",
    name: "Galeria de fotos",
    menu: true,
    component: <Gallery />,
    exact: true,
  },
  {
    path: "/casos-de-sucesso",
    name: "Casos de sucesso",
    menu: true,
    component: <Story />,
    exact: true,
  },
  {
    path: "/orcamento",
    name: "Orçamento",
    menu: true,
    component: <Reservation />,
    exact: true,
  },
  {
    path: "/espaco-petisco-da-deia",
    name: "Espaço Petisco da Deia",
    menu: true,
    component: <Venue />,
    exact: true,
  },
  {
    path: "*",
    name: "Não encontrado",
    menu: false,
    component: <NotFound />,
  },
];
