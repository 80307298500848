import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import GalleryList from "./GalleryList";

export default function Home() {
  const [gallerylist, setGalleryList] = useState(null);
  const [galleryText, setGalleryText] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {
      const responseGalleryText = await directus.items("texts").readOne(6, {
        fields: ["title", "subtitle", "description"],
      });
      setGalleryText(responseGalleryText);

      const responseGalleryList = await directus
        .items("venues_directus_files")
        .readByQuery({
          fields: ["*.*"],
          limit: 9,
          sort: "?",
        });
      setGalleryList(responseGalleryList);
    }
    fetchData();
  }, []);

  return (
    <div id="venue" className="gb-section section-padding">
      {galleryText && (
        <div className="container text-center">
          <div className="section-title">
            <h1 className="font-pacifico">{galleryText.title}</h1>
            <h2>{galleryText.subtitle}</h2>
            <span>
              <i className="fa fa-empire" aria-hidden="true"></i>
            </span>
            <p dangerouslySetInnerHTML={{ __html: galleryText.description }} />
          </div>
        </div>
      )}
      <GalleryList
        gallery={{ data: gallerylist?.data }}
        breakpointCols={{ default: 3, 1100: 2, 700: 1 }}
      />
    </div>
  );
}
