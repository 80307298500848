import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import { LazyLoadImage } from "react-lazy-load-image-component";
import about1 from "../shared/images/others/about1.webp";
import about2 from "../shared/images/others/about2.webp";
import bgMenu from "../shared/images/bg/pattern-menu.webp";
import Loading from "./Loading";
import { Link } from "react-router-dom";

export default function About() {
  const id = 1;
  const [about, setAbout] = useState(null);
  const [socialmedia, setSocialMedia] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const responseTexts = await directus.items("texts").readOne(id, {
        fields: ["title", "subtitle", "description"],
      });
      setAbout(responseTexts);

      const responseSocialMedia = await directus
        .items("social_media")
        .readOne(1, {
          fields: ["*"],
        });

      setSocialMedia(responseSocialMedia);
    }
    fetchData();
  }, []);

  return (
    <div
      id="about"
      className="gb-about-us section-padding"
      style={{ backgroundImage: "url(" + bgMenu + ")" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="about-images">
              <div className="about-image">
                <LazyLoadImage alt={"Petisco da deia"} src={about1} />
              </div>
              <div className="about-image image-absolute">
                <LazyLoadImage alt={"Petisco da deia"} src={about2} />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="about-info">
              {!about || !socialmedia ? (
                <Loading />
              ) : (
                <>
                  <div className="about-title">
                    <span className="font-pacifico">{about.title}</span>
                    <h1>{about.subtitle}</h1>
                  </div>
                  <p dangerouslySetInnerHTML={{ __html: about.description }} />
                  <Link
                    className="btn btn-primary video-link"
                    target={"_blank"}
                    to={socialmedia.url}
                    rel="noreferrer"
                  >
                    <i className={socialmedia.icon} aria-hidden="true"></i>{" "}
                    {socialmedia.title}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
