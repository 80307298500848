import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import { useForm } from "react-hook-form";
import { Button, InputGroup, Col, Form, Row, Alert } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import { toDateTime } from "../utils/date";
import emailjs from "@emailjs/browser";
import InputMask from "react-input-mask";
import Loading from "./Loading";
import bg1 from "../shared/images/bg/pattern-reservation.webp";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { toSeoUrl } from "../utils/string";
import Select from "react-select";
import { Helmet } from "react-helmet";

export default function Reservation() {
  const formStatus = {
    notSubmitted: "not-submitted",
    submitting: "submitting",
    submitted: "submitted",
    submitError: "submit-error",
  };
  const [validated, setValidated] = useState(false);
  const [company, setCompany] = useState(false);
  const [formSubmit, setFormSubmit] = useState(formStatus.notSubmitted);
  const [reservationText, setReservationText] = useState(null);
  const [reservationEmail, setReservationEmail] = useState(null);
  const [reservationTypelist, setReservationTypeList] = useState(null);
  const [serviceTypelist, setServiceTypeList] = useState(null);
  const [citylist, setCityList] = useState(null);
  const [drinkOptions, setDrinkOptions] = useState([]);
  const [venueOptions, setVenueOptions] = useState([]);
  const [peopleOptions, setPeopleOptions] = useState([]);
  const [partyOptions, setPartyOptions] = useState([]);
  const [kitchenOptions, setKitchenOptions] = useState([]);
  const [drinkValues, setDrinkValues] = useState([]);
  const [venueValues, setVenueValues] = useState([]);
  const [peopleValues, setPeopleValues] = useState([]);
  const [partyValues, setPartyValues] = useState([]);
  const [kitchenValues, setKitchenValues] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    async function fetchData() {
      const responseReservationOptions = await directus
        .items("reservation_options_reservation_inputs")
        .readByQuery({
          fields: ["*.*"],
          // sort: "title",
        });

      const drinkOptionsArr = [];
      const venueOptionsArr = [];
      const peopleOptionsArr = [];
      const partyOptionsArr = [];
      const kitchenOptionsArr = [];

      responseReservationOptions?.data.map((option) => {
        const reservationOption = {
          value: option.reservation_inputs_id.id,
          label: option.reservation_inputs_id.title,
          name: toSeoUrl(option.reservation_inputs_id.title),
        };

        if (option.reservation_options_id.id === 1) {
          drinkOptionsArr.push(reservationOption);
        } else if (option.reservation_options_id.id === 2) {
          peopleOptionsArr.push(reservationOption);
        } else if (option.reservation_options_id.id === 3) {
          partyOptionsArr.push(reservationOption);
        } else if (option.reservation_options_id.id === 4) {
          kitchenOptionsArr.push(reservationOption);
        } else if (option.reservation_options_id.id === 5) {
          venueOptionsArr.push(reservationOption);
        }
        return option;
      });

      setDrinkOptions(
        drinkOptionsArr.sort(
          (a, b) => a.value !== 20 && a.name.localeCompare(b.name)
        )
      );
      setPeopleOptions(
        peopleOptionsArr.sort(
          (a, b) => a.value !== 20 && a.name.localeCompare(b.name)
        )
      );
      setPartyOptions(
        partyOptionsArr.sort(
          (a, b) => a.value !== 20 && a.name.localeCompare(b.name)
        )
      );
      setKitchenOptions(
        kitchenOptionsArr.sort(
          (a, b) => a.value !== 20 && a.name.localeCompare(b.name)
        )
      );
      setVenueOptions(
        venueOptionsArr.sort(
          (a, b) => a.value !== 20 && a.name.localeCompare(b.name)
        )
      );

      const responseCompany = await directus.items("company").readOne(1, {
        fields: ["company_name"],
      });
      setCompany(responseCompany);

      const responseReservationEmail = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/mails?fields=title&filter[main][eq]=1`
      );
      setReservationEmail(responseReservationEmail.data.data);

      const responseReservationText = await directus.items("texts").readOne(5, {
        fields: ["title", "subtitle", "description"],
      });
      setReservationText(responseReservationText);

      const responseCityList = await directus.items("cities").readByQuery({
        fields: ["id", "name"],
        sort: "name",
      });
      setCityList(responseCityList);

      const responseReservationTypeList = await directus
        .items("reservation_type")
        .readByQuery({
          fields: ["id", "title"],
          sort: "title",
        });
      setReservationTypeList(responseReservationTypeList);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setValidated(Object.entries(errors).length);
  }, [errors]);

  const renderMessageHTML = (rawHTML) => {
    return rawHTML.replace(/<[^>]+>/g, "");
  };

  const onSubmit = async (data) => {
    try {
      if (formSubmit === formStatus.submitting) {
        return;
      }

      if (formSubmit === formStatus.submitted) {
        return;
      }

      var multiSelectMsg = "<br/>";

      const venueMsg =
        venueValues.length > 0
          ? venueValues.map((value) => `<p>${value.name}</p>`)
          : "<p>Não informado</p>";
      multiSelectMsg = `${multiSelectMsg} 
      <p><b>Deseja incluir espaço para evento?</b></p> 
      ${venueMsg.toString().replaceAll(",", "\n")} \n`;

      const drinkMsg =
        drinkValues.length > 0
          ? drinkValues.map((value) => `<p>${value.name}</p>`)
          : "<p>Não informado</p>";
      multiSelectMsg = `${multiSelectMsg} 
      <p><b>Deseja incluir bebidas?</b></p> 
      ${drinkMsg.toString().replaceAll(",", "\n")} \n`;

      const peopleMsg =
        peopleValues.length > 0
          ? peopleValues.map((value) => `<p>${value.name}</p>`)
          : "<p>Não informado</p>";
      multiSelectMsg = `${multiSelectMsg} 
      <p><b>Deseja incluir serviço de pessoal?</b></p> 
      ${peopleMsg.toString().replaceAll(",", "\n")} \n`;

      const partyMsg =
        partyValues.length > 0
          ? partyValues.map((value) => `<p>${value.name}</p>`)
          : "<p>Não informado</p>";
      multiSelectMsg = `${multiSelectMsg} 
      <p><b>Deseja incluir material de festa?</b></p> 
      ${partyMsg.toString().replaceAll(",", "\n")} \n`;

      const kitchenMsg =
        kitchenValues.length > 0
          ? kitchenValues.map((value) => `<p>${value.name}</p>`)
          : "<p>Não informado</p>";
      multiSelectMsg = `${multiSelectMsg} 
      <p><b>Deseja incluir material de cozinha?</b></p> 
      ${kitchenMsg.toString().replaceAll(",", "\n")} \n`;

      setFormSubmit(formStatus.submitting);
      const storageData = {
        ...data,
        date: toDateTime(data.date),
        message: renderMessageHTML(data.message + multiSelectMsg),
      };
      var post = await directus.items("reservations").createOne(storageData);
    } catch (error) {
      setFormSubmit(formStatus.submitError);
      setErrorMsg(`Ocorreu um erro ao salvar o orçamento. \n${error}`);
    }
    if (post.id) {
      const serviceTypePrefix = serviceTypelist.find(
        (serviceType) =>
          data.service_type_id.toString() === serviceType.id.toString()
      ).prefix;
      var emailData = {
        ...data,
        message: data.message + multiSelectMsg,
        reservationId: serviceTypePrefix
          ? `${serviceTypePrefix}-${post.id}`
          : post.id,
        company_name: company.company_name,
        date:
          new Date(data.date).toLocaleString("pt-BR") +
          " - " +
          new Date(data.date).toLocaleString("pt-BR", {
            weekday: "long",
          }),
        fromEmail: data.email,
        toEmail: reservationEmail[0].title,
        reservationType: reservationTypelist.data.find(
          (reservationType) =>
            data.reservation_type_id.toString() ===
            reservationType.id.toString()
        ).title,
        serviceType: serviceTypelist.find(
          (serviceType) =>
            data.service_type_id.toString() === serviceType.id.toString()
        ).title,
        city: citylist.data.find(
          (city) => data.city_id.toString() === city.id.toString()
        ).name,
      };
      emailjs
        .send(
          "service_gypze86",
          "template_yh6ccgh",
          emailData,
          "ybIbCv3n9jOBUpFWl"
        )
        .then(
          (result) => {
            setFormSubmit(formStatus.submitted);
            reset();
          },
          (error) => {
            setFormSubmit(formStatus.submitError);
            setErrorMsg(
              `Não foi possível enviar o orçamento para ${reservationEmail[0].title}. \n${error.text}`
            );
          }
        );
    }
  };

  const handleDateType = (event) => {
    event.target.type = "datetime-local";
  };

  const handleDrinkMultiselect = (event) => {
    setDrinkValues([]);
    event.map((e) =>
      setDrinkValues((values) => [...values, { name: e.label }])
    );
  };
  const handlePeopleMultiselect = (event) => {
    setPeopleValues([]);
    event.map((e) =>
      setPeopleValues((values) => [...values, { name: e.label }])
    );
  };
  const handlePartyMultiselect = (event) => {
    setPartyValues([]);
    event.map((e) =>
      setPartyValues((values) => [...values, { name: e.label }])
    );
  };
  const handleKitchenMultiselect = (event) => {
    setKitchenValues([]);
    event.map((e) =>
      setKitchenValues((values) => [...values, { name: e.label }])
    );
  };
  const handleVenueMultiselect = (event) => {
    setVenueValues([]);
    setVenueValues([
      { value: event.value, label: event.label, name: event.label },
    ]);
  };

  const handleReservationTypeSelect = async (event) => {
    const reservationTypeList = await axios(
      `${process.env.REACT_APP_DIRECTUS_URL}/items/reservation_type_service_type?fields=*.*&filter[reservation_type_id][eq]=${event.target.value}`
    );
    const reservationTypeArr = reservationTypeList.data.data;
    const serviceTypeListArr = [];
    reservationTypeArr?.map((item) => {
      serviceTypeListArr.push({
        id: item.service_type_id.id,
        title: item.service_type_id.title,
        prefix: item.service_type_id.prefix,
      });
    });

    setServiceTypeList(
      serviceTypeListArr.sort(
        (a, b) => a.id !== 4 && a.title.localeCompare(b.title)
      )
    );
  };

  const gtagConversion = () => (
    <Helmet>
      <script>
        {`gtag('event', 'conversion', {'send_to': 'AW-1037744139/fm9vCPbmq4YYEIvw6u4D'})`}
      </script>
    </Helmet>
  );

  return (
    <div
      id="reservation"
      className="gb-section section-padding"
      style={{ backgroundImage: "url(" + bg1 + ")" }}
    >
      <div className="container text-center">
        {reservationText && (
          <div className="container text-center">
            <div className="section-title">
              <h1 className="font-pacifico">{reservationText.title}</h1>
              <h2>{reservationText.subtitle}</h2>
              <span>
                <i className="fa fa-empire" aria-hidden="true"></i>
              </span>
              <p
                dangerouslySetInnerHTML={{
                  __html: reservationText.description,
                }}
              />
            </div>
          </div>
        )}
        {/* {console.log(errors)} */}
        {Object.entries(errors).length > 0 && (
          <div className="alert alert-danger text-left" role="alert">
            {Object.entries(errors).map(([type, error]) => {
              return <li key={type}>{error.message}</li>;
            })}
          </div>
        )}
        {!reservationTypelist ? (
          <Loading />
        ) : (
          <Form
            noValidate
            validated={validated}
            className="gb-form"
            name="contact-form"
            method="post"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Select
                  onChangeCapture={handleReservationTypeSelect}
                  onFocus={() => setServiceTypeList([])}
                  name="reservation_type_id"
                  className="form-control"
                  required
                  {...register("reservation_type_id", {
                    required: "Informe o tipo de evento",
                    min: { value: 1, message: "Informe o tipo de evento" },
                  })}
                >
                  <option value="">Tipo de Evento</option>
                  {reservationTypelist.data.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.title}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="reservation_type_id"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Select
                  name="service_type_id"
                  className="form-control"
                  required
                  {...register("service_type_id", {
                    required: "Informe o tipo de cardápio",
                    min: { value: 1, message: "Informe o tipo de cardápio" },
                  })}
                >
                  <option value="">Tipo de cardápio</option>
                  {serviceTypelist?.map((service) => (
                    <option key={service.id} value={service.id}>
                      {service.title}
                    </option>
                  ))}
                </Form.Select>

                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="service_type_id"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  type="text"
                  name="date"
                  placeholder="Data do evento"
                  required
                  onFocus={handleDateType}
                  {...register("date", {
                    required: "Informe a data do evento",
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="date"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  type="number"
                  name="invited"
                  placeholder="Qtd de convidados"
                  required
                  {...register("invited", {
                    required: "Informe a quantidade de convidados",
                    min: {
                      value: 1,
                      message: "Informe uma quantidade de convidados válida",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="invited"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Select
                  name="city_id"
                  className="form-control"
                  required
                  {...register("city_id", {
                    required: "Informe a cidade onde será realizado o evento",
                    min: {
                      value: 1,
                      message: "Informe a cidade onde será realizado o evento",
                    },
                  })}
                >
                  <option value="">Cidade do Evento</option>
                  {citylist.data.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="city"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  type="text"
                  name="neighborhood"
                  placeholder="Bairro do evento"
                  required
                  {...register("neighborhood", {
                    required: "Informe o bairro onde será realizado o evento",
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="neighborhood"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <Form.Group
                as={Col}
                md="4"
                className="form-group"
                controlId="validationCustomUsername"
              >
                <InputGroup hasValidation>
                  <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="E-mail para contato"
                    aria-describedby="inputGroupPrepend"
                    required
                    {...register("email", {
                      required: "Informe um e-mail para contato",
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: "Informe um e-mail válido para contato",
                      },
                    })}
                  />
                  <Form.Control.Feedback type="invalid">
                    <ErrorMessage
                      errors={errors}
                      name="email"
                      render={({ message }) => <p>{message}</p>}
                    />
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="4" className="form-group">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Nome para contato"
                  required
                  {...register("name", {
                    required: "Informe um nome para contato",
                    minLength: {
                      value: 10,
                      message: "Informe nome e sobrenome para contato",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="name"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="4" className="form-group">
                <InputMask
                  type="text"
                  name="phone1"
                  mask="(99) 9 9999 9999"
                  inputMode={"numeric"}
                  placeholder="Telefone para contato"
                  className="form-control"
                  required
                  {...register("phone1", {
                    required: "Informe um telefone para contato",
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="phone1"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            <Row>
              <div className="col-sm-4">
                <div className="form-group">
                  <Select
                    placeholder={"Deseja incluir espaço para evento?"}
                    name={"venue"}
                    options={venueOptions}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    onChange={(event) => handleVenueMultiselect(event)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <Select
                    placeholder={"Deseja incluir bebidas?"}
                    name={"drink"}
                    options={drinkOptions}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(event) => handleDrinkMultiselect(event)}
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-group">
                  <Select
                    placeholder={"Deseja incluir serviço de pessoal?"}
                    name={"people"}
                    options={peopleOptions}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(event) => handlePeopleMultiselect(event)}
                  />
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-sm-6">
                <div className="form-group">
                  <Select
                    placeholder={"Deseja incluir material de festa?"}
                    name={"party"}
                    options={partyOptions}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(event) => handlePartyMultiselect(event)}
                  />
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <Select
                    placeholder={"Deseja incluir material de cozinha?"}
                    name={"kitchen"}
                    options={kitchenOptions}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={(event) => handleKitchenMultiselect(event)}
                  />
                </div>
              </div>
              <Form.Group as={Col} md="12" className="form-group">
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  placeholder="Conte-nos mais sobre seu evento para que possamos lhe atender da melhor forma possível. "
                  required
                  {...register("message", {
                    required: "Conte-nos mais sobre seu evento",
                    min: {
                      value: 15,
                      message: "Conte-nos mais sobre seu evento",
                    },
                  })}
                />
                <Form.Control.Feedback type="invalid">
                  <ErrorMessage
                    errors={errors}
                    name="message"
                    render={({ message }) => <p>{message}</p>}
                  />
                </Form.Control.Feedback>
              </Form.Group>
            </Row>
            {formSubmit === formStatus.submitError && (
              <Alert variant="danger">{errorMsg}</Alert>
            )}
            <Button
              type="submit"
              className="buttonload"
              onClick={() => gtagConversion()}
              variant={
                formSubmit === formStatus.submitted
                  ? "btn btn-success"
                  : "btn btn-primary"
              }
              disabled={formSubmit === formStatus.submitted}
            >
              {formSubmit === formStatus.notSubmitted && (
                <span>Enviar orçamento</span>
              )}
              {formSubmit === formStatus.submitting && (
                <span>
                  <i className="fa fa-spinner fa-spin"></i> Enviando...
                </span>
              )}
              {formSubmit === formStatus.submitted && (
                <span>
                  <i className="fa fa-check"></i> Enviado com sucesso
                </span>
              )}
              {formSubmit === formStatus.submitError && (
                <span>Enviar novamente</span>
              )}
            </Button>
          </Form>
        )}
      </div>
    </div>
  );
}
