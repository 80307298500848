import React from "react";
import { DirectusProvider } from "react-directus";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import DefaultLayout from "./layouts/Default";
import "modern-normalize";

export default function App() {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <DefaultLayout>
        <DirectusProvider apiUrl={process.env.REACT_APP_DIRECTUS_URL}>
          <Routes>
            {routes.map((route) => (
              <Route
                key={route.name}
                path={route.path}
                element={route.component}
              />
            ))}
          </Routes>
        </DirectusProvider>
      </DefaultLayout>
    </BrowserRouter>
  );
}
