import React from "react";
import ReactLoading from "react-loading";

const Loading = () => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <ReactLoading
      type={"bubbles"}
      color={"#dfa058"}
      height={"10%"}
      width={"10%"}
    />
  </div>
);

export default Loading;
