import React from "react";
import { useEffect } from "react";
import AboutComponent from "../components/About";
import PartnerComponent from "../components/Partner";
import TestmonialComponent from "../components/Testimonial";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      <AboutComponent />
      <TestmonialComponent />
      <PartnerComponent />
    </main>
  );
}
