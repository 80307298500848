import React from "react";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Slideshow from "../components/Slideshow";
import BreadCrumb from "../components/BreadCrumb";
import { useLocation } from "react-router-dom";
import { routes } from "../routes";

import "../shared/stylesheet/bootstrap.min.css";
import "../shared/stylesheet/font-awesome.min.css";
import "../shared/stylesheet/structure.css";
import "../shared/stylesheet/main.css";
import "../shared/stylesheet/preset3.css";
import "../shared/stylesheet/responsive.css";

export default function DefaultLayout({ children }) {
  const location = useLocation();
  const isHomePage = routes.some(() => location.pathname === "/");

  return (
    <>
      <div className="layout">
        <Menu /> {isHomePage ? <Slideshow /> : <BreadCrumb />} {children}
        <Footer />
      </div>
    </>
  );
}
