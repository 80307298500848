import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import Loading from "./Loading";

export default function Slideshow() {
  const id = 1;
  const [slideshow, setSlideshow] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await directus.items("slideshow").readOne(id, {
          fields: ["title", "subtitle", "description"],
        });
        setSlideshow(response);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? <div id="preloader"></div> : <></>}
      <div id="home" className="gb-banner section-before">
        <div className="container">
          <div className="banner-info">
            {!slideshow ? (
              <Loading />
            ) : (
              <>
                <h1 className="font-pacifico">{slideshow.title}</h1>
                <h2>{slideshow.subtitle}</h2>
                <p
                  dangerouslySetInnerHTML={{ __html: slideshow.description }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
