import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { routes } from "../routes";
import { directus } from "../services/directus";
import { toSeoUrl } from "../utils/string";
import Loading from "./Loading";

export default function BreadCrumb() {
  const [breadcrumb, setBreadCrumb] = useState(null);
  const [service, setService] = useState(null);
  const location = useLocation();
  const route = location.pathname.split("/")[1];
  const subRoute = location.pathname.split("/")[2];
  const homePage = routes.find((route) => route.path === "/");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const breadcrumbs = await directus.items("breadcrumb").readByQuery({
          fields: ["id", "title", "subtitle", "description"],
        });

        const currentBreadcrumb = breadcrumbs.data.find((breadcrumb) => {
          // console.log(1, route, toSeoUrl(breadcrumb.title));
          return route === toSeoUrl(breadcrumb.title);
        });
        // console.log(currentBreadcrumb);
        setBreadCrumb(currentBreadcrumb);

        const services = await directus.items("services").readByQuery({
          fields: ["id", "title", "subtitle"],
        });

        const currentService = services.data.find((service) => {
          // console.log(2, route, toSeoUrl(service.title));
          return subRoute === toSeoUrl(service.title);
        });
        // console.log(currentBreadcrumb, currentService);
        setService(currentService);
        let title = `Petisco da Deia - ${currentBreadcrumb.title}`;
        if (currentService) {
          title = `${title} - ${currentService.title}`;
        }
        document.title = title;
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [route, subRoute]);

  return (
    <>
      {isLoading ? <div id="preloader"></div> : <></>}
      <div className="gb-breadcrumb section-before gb-parallax">
        <div className="container text-center">
          <div className="breadcrumb-info">
            <ol className="breadcrumb">
              {!breadcrumb ? (
                <Loading />
              ) : (
                <>
                  <li className="breadcrumb-item font-pacifico">
                    <Link to={homePage?.path}>{homePage?.name}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {breadcrumb && service ? (
                      <Link to={`/${toSeoUrl(breadcrumb.title)}`}>
                        {breadcrumb.title}
                      </Link>
                    ) : (
                      breadcrumb?.title
                    )}
                  </li>
                  {service && (
                    <li className="breadcrumb-item active">{service.title}</li>
                  )}
                </>
              )}
            </ol>
            <div className="page-title">
              <h1>{breadcrumb?.subtitle}</h1>
              <p
                dangerouslySetInnerHTML={{ __html: breadcrumb?.description }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
