import { useState } from "react";
import { useEffect } from "react";
import { routes } from "../routes";
import { useLocation } from "react-router";
import logo from "../shared/images/logo.png";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

export default function Header() {
  const [scroll, setScroll] = useState(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const currentRoute = `/${location.pathname.split("/")[1]}`;

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  return (
    <header>
      <div className="gb-menu">
        <nav
          className={
            scroll
              ? "navbar fixed-top navbar-toggleable-md menu-bg animated slideInDown"
              : "navbar fixed-top navbar-toggleable-md"
          }
        >
          <div className="container">
            <Button
              variant="none"
              className="navbar-toggler navbar-toggler-right collapsed"
              aria-controls="navbarNav"
              aria-expanded={open}
              onClick={() => setOpen(!open)}
            >
              <span className="navbar-toggler-icon"></span>
            </Button>
            <Link
              className="navbar-brand"
              to={routes[0].path}
              onClick={() => window.scrollTo(0, 0)}
            >
              <LazyLoadImage className="img-fluid" src={logo} alt="Logo" />
            </Link>
            <Collapse in={open}>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarNav"
              >
                <ul className="navbar-nav">
                  {routes.map(
                    (route) =>
                      route.menu && (
                        <li
                          key={route.name}
                          className={
                            currentRoute === route.path
                              ? "nav-item active"
                              : "nav-item"
                          }
                        >
                          <Link
                            onClick={() => setOpen(!open)}
                            className="nav-link"
                            to={route.path}
                          >
                            {route.name}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </Collapse>
          </div>
        </nav>
      </div>
    </header>
  );
}
