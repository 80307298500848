import React from "react";
import { useEffect } from "react";
import ReservartionComponent from "../components/Reservartion";

export default function Reservartion() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <ReservartionComponent />
    </main>
  );
}
