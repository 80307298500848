import React from "react";
import { routes } from "./index";
import { Link, useLocation } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import about1 from "../shared/images/others/about1.webp";
import about2 from "../shared/images/others/about2.webp";
import bgMenu from "../shared/images/bg/pattern-menu.webp";
import { useEffect } from "react";

export default function About() {
  const location = useLocation();
  const homePage = routes.find((route) => route.path === "/");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div
      id="about"
      className="gb-about-us section-padding"
      style={{ backgroundImage: "url(" + bgMenu + ")" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="about-images">
              <div className="about-image">
                <LazyLoadImage alt={"Petisco da deia"} src={about1} />
              </div>
              <div className="about-image image-absolute">
                <LazyLoadImage alt={"Petisco da deia"} src={about2} />
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="about-info">
              <div className="about-title">
                <span className="font-pacifico">Página não encontrada</span>
                <h1>{location.pathname}</h1>
                <p>{"A página informada não existe. Erro 404."}</p>
              </div>
            </div>
            <Link to={homePage.path}>Clique aqui</Link>{" "}
            <span>para voltar para a página inicial.</span>
          </div>
        </div>
      </div>
    </div>
  );
}
