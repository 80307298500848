import React from "react";
import "../shared/stylesheet/icofont.css";

export default function Parallax({ parallax }) {
  return (
    <div
      className="gb-convenience section-before section-padding gb-parallax"
      style={{ backgroundImage: "url(" + parallax.bg + ")" }}
    >
      <div className="container text-center">
        <div className="row">
          <div className="col-sm-4">
            <div className="convenience">
              <span className="icon">
                <i className={parallax.icon1}></i>
              </span>
              <h1>{parallax.text1}</h1>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="convenience">
              <span className="icon">
                <i className={parallax.icon2}></i>
              </span>
              <h1>{parallax.text2}</h1>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="convenience">
              <span className="icon">
                <i className={parallax.icon3}></i>
              </span>
              <h1>{parallax.text3}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
