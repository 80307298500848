import React from "react";
import { useEffect } from "react";
import ServiceListComponent from "../components/ServiceList";

export default function Service() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main>
      <ServiceListComponent service={{ limit: -1, sort: "title" }} />
    </main>
  );
}
