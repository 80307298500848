import React, { useEffect, useState } from "react";
import { useDirectus } from "react-directus";
import { directus } from "../services/directus";
import Slider from "react-slick";
import "../shared/stylesheet/icofont.css";
import bgMenu from "../shared/images/bg/pattern-menu.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loading from "./Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Partner() {
  const id = 3;
  const [partnerText, setPartnerText] = useState(null);
  const [partnerImg, setPartnerImg] = useState(null);
  const { DirectusImage } = useDirectus();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  useEffect(() => {
    async function fetchData() {
      const responseTexts = await directus.items("texts").readOne(id, {
        fields: ["title", "subtitle", "description"],
      });

      setPartnerText(responseTexts);

      const responsePartners = await directus
        .items("partners_directus_files")
        .readByQuery({
          fields: ["*.*"],
        });
      setPartnerImg(responsePartners);
    }
    fetchData();
  }, []);
  return (
    <div
      className="gb-barnds section-padding"
      style={{ backgroundImage: "url(" + bgMenu + ")" }}
    >
      <div className="container text-center">
        {!partnerText || !partnerImg ? (
          <Loading />
        ) : (
          <>
            <div className="section-title">
              <h1 className="font-pacifico">{partnerText.title}</h1>
              <h2>{partnerText.subtitle}</h2>
              <span>
                <i className="fa fa-empire" aria-hidden="true"></i>
              </span>
              <p
                dangerouslySetInnerHTML={{ __html: partnerText.description }}
              />
            </div>
            <div className="barnd-slider">
              <Slider {...settings}>
                {partnerImg.data.map((p, index) => (
                  <div key={index} className="barnd">
                    <DirectusImage
                      asset={p.directus_files_id.private_hash}
                      fit="cover"
                      quality="75"
                      render={({ asset, url }) => (
                        <LazyLoadImage src={url} alt={asset.title} />
                      )}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
