import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { directus } from "../services/directus";
import { Button } from "react-bootstrap";
import { toSeoUrl } from "../utils/string";
import { LazyLoadImage } from "react-lazy-load-image-component";
import axios from "axios";
import GalleryListComponent from "../components/GalleryList";
import about1 from "../shared/images/others/about1.webp";
import about2 from "../shared/images/others/about2.webp";
import bgMenu from "../shared/images/bg/pattern-menu.webp";
import Loading from "../components/Loading";

export default function ServiceDetail() {
  const [gallerylist, setGalleryList] = useState(null);
  const [activeGallerylist, setAtiveGalleryList] = useState(null);
  const [socialmedia, setSocialMedia] = useState(null);
  const defaultLimit = 12;
  const [limit, setLimit] = useState(defaultLimit);
  const location = useLocation();
  const subRoute = location.pathname.split("/")[2];
  const hasMoreToshow =
    activeGallerylist?.length % 12 === 0 && activeGallerylist?.length === limit;

  useEffect(() => {
    window.scrollTo(0, 0);

    async function fetchData() {
      const services = await directus.items("services").readByQuery({
        fields: ["id", "title", "subtitle"],
      });

      const currentService = services.data.find((service) => {
        // console.log(subRoute, toSeoUrl(service.title));
        return subRoute === toSeoUrl(service.title);
      });

      const response = await axios(
        `${process.env.REACT_APP_DIRECTUS_URL}/items/services_directus_files?fields=*.*&sort=?&filter[services_id][eq]=${currentService.id}`
      );
      setGalleryList(response.data.data);
      setAtiveGalleryList(response.data.data.slice(0, defaultLimit));

      const responseSocialMedia = await directus
        .items("social_media")
        .readOne(1, {
          fields: ["*"],
        });

      setSocialMedia(responseSocialMedia);
    }
    fetchData();
  }, [subRoute]);

  const setNewLimit = (newLimit) => {
    const sumLimit = limit + newLimit;
    setAtiveGalleryList(gallerylist?.slice(0, sumLimit));
    setLimit(sumLimit);
  };

  return (
    <main>
      <div
        id="about"
        className="gb-about-us section-padding"
        style={{ backgroundImage: "url(" + bgMenu + ")" }}
      >
        <div className="container">
          {!activeGallerylist || !socialmedia ? (
            <Loading />
          ) : (
            <div className="row">
              <div className="col-md-5">
                <div className="about-images">
                  <div className="about-image">
                    <LazyLoadImage alt={"Petisco da deia"} src={about1} />
                  </div>
                  <div className="about-image image-absolute">
                    <LazyLoadImage alt={"Petisco da deia"} src={about2} />
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="about-info">
                  <div className="about-title">
                    <span className="font-pacifico">
                      {activeGallerylist[0].services_id.title}
                    </span>
                    <h1>{activeGallerylist[0].services_id.subtitle}</h1>
                  </div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: activeGallerylist[0].services_id.description,
                    }}
                  />
                  <Link
                    className="btn btn-primary video-link"
                    target={"_blank"}
                    to={socialmedia.url}
                    rel="noreferrer"
                  >
                    <i className={socialmedia.icon} aria-hidden="true"></i>{" "}
                    {socialmedia.title}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
        <div id="portfolio-content" className="food-menu mt-5">
          <GalleryListComponent
            gallery={{ data: activeGallerylist }}
            breakpointCols={{ default: 4, 1100: 2, 700: 1 }}
          />
        </div>
        {hasMoreToshow && (
          <div id="btn-show-more" className="text-center">
            <Button
              onClick={() => setNewLimit(defaultLimit)}
              className="btn btn-primary"
            >
              Carregar mais...
            </Button>
          </div>
        )}
      </div>
    </main>
  );
}
