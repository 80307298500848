import React, { useEffect, useState } from "react";
import { directus } from "../services/directus";
import bgMenu from "../shared/images/bg/footer-bg.webp";
import Loading from "./Loading";
import Whatsapp from "./Whatsapp";

export default function Footer() {
  const [addresses, setAddress] = useState(null);
  const [emails, setEmail] = useState(null);
  const [phones, setPhone] = useState(null);
  const [socials, setSocial] = useState(null);
  const [companyName, setCompanyNames] = useState(null);

  useEffect(() => {
    async function fetchData() {
      const addresses = await directus.items("addresses").readByQuery({
        fields: ["*"],
        sort: "-main",
      });

      setAddress(addresses);

      const phones = await directus.items("phones").readByQuery({
        fields: ["*"],
        sort: "-main",
      });
      setPhone(phones);

      const emails = await directus.items("mails").readByQuery({
        fields: ["*"],
        sort: "-main",
      });
      setEmail(emails);

      const socials = await directus.items("social_media").readByQuery({
        fields: ["*"],
        sort: "-main",
      });
      setSocial(socials);

      const companyNames = await directus.items("company").readByQuery({
        fields: ["*"],
      });
      setCompanyNames(companyNames);
    }
    fetchData();
  }, []);
  return (
    <div className="gb-footer">
      <div
        className="footer-top section-padding section-before gb-parallax"
        style={{ backgroundImage: "url(" + bgMenu + ")" }}
      >
        <Whatsapp />
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget opening-hour">
                <h3>Agende sua visita</h3>
                <ul className="global-list">
                  <li>
                    {" "}
                    Segunda <span className="time">08:00 - 18:00</span>
                  </li>
                  <li>
                    {" "}
                    Terça <span className="time">08:00 - 18:00</span>
                  </li>
                  <li>
                    {" "}
                    Quarta <span className="time">08:00 - 18:00</span>
                  </li>
                  <li>
                    {" "}
                    Quinta <span className="time">08:00 - 18:00</span>
                  </li>
                  <li>
                    {" "}
                    Sexta <span className="time">08:00 - 18:00</span>
                  </li>
                  <li>
                    {" "}
                    Sábado <span className="star">*</span>{" "}
                    <span className="time">08:00 - 12:00</span>
                  </li>
                </ul>
                <span className="weekly-closed">
                  Não agendamos visita aos domingos
                </span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="footer-widget locations">
                {!addresses ? (
                  <Loading />
                ) : (
                  <>
                    <h3>
                      {addresses.length > 1 ? "Nossos endereços" : "Endereço"}
                    </h3>
                    {addresses.data.map((address) => (
                      <span key={address.id}>
                        <span>{address.title}</span>
                        <address>
                          {address.address} <br />
                          {address.neighborhood} <br />
                        </address>
                      </span>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="footer-widget contact">
                <h3>Contato</h3>
                <ul className="global-list">
                  <li>
                    <span className="mr-1">Telefone :</span>
                    {!phones ? (
                      <Loading />
                    ) : (
                      <div className="media-body">
                        {phones.data.map((phone) => (
                          <p key={phone.id}>
                            <a
                              rel="noreferrer"
                              target={"_blank"}
                              href={`tel:${phone.title}`}
                            >
                              {phone.title}
                            </a>
                          </p>
                        ))}
                      </div>
                    )}
                  </li>
                  <li>
                    <span className="mr-1">Email :</span>
                    <div className="media-body">
                      {!emails ? (
                        <Loading />
                      ) : (
                        <div className="media-body">
                          {emails.data.map((mail) => (
                            <p key={mail.id}>
                              <a
                                rel="noreferrer"
                                target={"_blank"}
                                href={`mailto:${mail.title}`}
                              >
                                {mail.title}
                              </a>
                            </p>
                          ))}
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-6">
              <div className="footer-widget newsletter">
                <h3>Redes sociais</h3>
                <div className="">
                  <ul className="global-list footer-social">
                    {!socials ? (
                      <Loading />
                    ) : (
                      <div className="media-body">
                        {socials.data.map((social) => (
                          <li key={social.id}>
                            <p>
                              <a
                                rel="noreferrer"
                                target={"_blank"}
                                href={social.url}
                              >
                                <i
                                  className={social.icon}
                                  aria-hidden="true"
                                ></i>{" "}
                                {social.title}
                              </a>
                            </p>
                          </li>
                        ))}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom text-center">
        <div className="container">
          <span>
            &copy; Copyright {new Date().getFullYear()}{" "}
            {!companyName ? (
              <Loading />
            ) : (
              <a href="/">{companyName.data[0].business_name}</a>
            )}
            . Todos os direitos reservados.
          </span>
        </div>
      </div>
    </div>
  );
}
